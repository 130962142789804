<template>
  <center>
    <h1>Página não encontrada!</h1>
  </center>
</template>

<script>

export default {
  name: 'NotFound'
}

</script>

<style scoped>

  center {
    margin: 15vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }

</style>
